var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    width: _vm.narrow ? '40%' : '100%',
    margin: '0 auto',
  })},[_c('MobileFormContent',{attrs:{"content-sections":[
      'smallTitle',
      'subtitle',
    ],"small-title-text":'payments.providers.title',"subtitle-text":_vm.$t('payments.providers.subtitle', { payment_name: _vm.payment.name })}}),_vm._l((_vm.availableProviders),function(provider){return _c('MultiIconSelectionButton',{key:provider.original.provider_code,attrs:{"label":provider.original.name,"icons-left":provider.iconsLeft,"icons-right":provider.iconsRight,"selected":_vm.currentProvider?.provider_code === provider.original.provider_code},on:{"clicked":function($event){return _vm.selectMethodProvider(provider.original)}}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }