<template>
  <section id="widget-invoker-root">
    <SpinningLoader v-if="loading" :active="loading" />
  </section>
</template>

<script>
import SpinningLoader from '@/components/organisms/general/SpinningLoader.vue';
import { PAYMENT_STATES } from '@/constants/payments';
import { mapGetters } from 'vuex';

export default {
  name: 'PaymentEmbeddedWidget',
  components: {
    SpinningLoader,
  },
  props: {
    interface: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    widgetReady: false,
    widget: null,
    lastStatus: null,
  }),
  computed: {
    ...mapGetters({
      loading: 'payments/loading',
    }),
  },
  watch: {
    widgetReady(value) {
      if (value) {
        // Open the Widget that has been mounted
        this.interface.openWidget(this.widget);
      }
    },
  },
  async created() {
    // Upon creation of the component, setup the corresponding script and start the chain of events
    this.setupProvider();
  },
  destroyed() {
    if (this.widget) {
      this.widgetReady = false;
      this.interface.destroyWidget(this.widget);
    }
  },
  methods: {
    statusUpdate(status) {
      const internalStatus = this.interface.translateStatus(status);
      if (this.lastStatus !== PAYMENT_STATES.FAILED) {
        this.lastStatus = internalStatus;
      }
    },
    successfulPayment(flowData) {
      const submissionData = this.interface.buildFlowSubmissionData({ flowData });
      this.$emit('success', submissionData);
    },
    closedWidget() {
      if (this.lastStatus === PAYMENT_STATES.FAILED) {
        this.$emit('retry');
      } else {
        this.$emit('exit');
      }
    },
    setupProvider() {
      const callbacks = {
        loadedScript: () => {
          this.loadWidget();
        },
      };
      this.interface.setup({ callbacks });
    },
    async loadWidget() {
      const callbacks = {
        success: this.successfulPayment,
        exit: this.closedWidget,
        flowEvent: this.statusUpdate,
      };
      const config = this.interface.createConfig({ callbacks });
      if (this.widget) {
        this.interface.destroyWidget(this.widget);
      }
      this.widget = this.interface.invoke({ config });
      this.widgetReady = true;
    },
  },

};
</script>
