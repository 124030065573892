<template>
  <div class="grouped-inputs">
    <TextAtom
      class="grouped-inputs__title"
      tag="h1"
      :value="title"
    />
    <!-- 1. Country - For now forced to the tenant's country -->
    <section>
      <TextAtom
        tag="h2"
        :value="'inputs.detailed-address.country'"
      />
      <AutocompleteInput
        :items="parsedCountries"
        :value="country"
        :readonly="!!forceCountry"
        return-object
        label-key="name"
        @input="(value) => updateBoundValue('country', value)"
      />
      <TextAtom
        v-for="(error, index) in (showErrors ? countryErrors : [])"
        :key="`nat_err_${index}`"
        tag="p"
        :value="error"
        color="error-check"
      />
    </section>
    <!-- 2. Region & City
      If customSubdivisions is true, then the inputs are simple text fields,
      where the user can input anything. Otherwise, the options are obtained using the Geotools Service
    -->
    <div
      v-if="customSubdivisions"
      class="flex"
      :class="{
        'flex-row': compact,
        'flex-col': !compact,
        'justify-space-between': compact,
      }"
    >
      <section>
        <TextAtom
          tag="h2"
          value="inputs.detailed-address.upper-level"
        />
        <TextInput
          :value="upperLevelRegion"
          @setValue="(value) => updateBoundValue('upperLevelRegion', value)"
        />
        <TextAtom
          v-for="(error, index) in (showErrors ? upperLevelErrors : [])"
          :key="`ul_err_${index}`"
          tag="p"
          :value="error"
          color="error-check"
        />
      </section>
      <section>
        <TextAtom
          tag="h2"
          :value="'inputs.detailed-address.lower-level'"
        />
        <TextInput
          :value="lowerLevelRegion"
          @setValue="(value) => updateBoundValue('lowerLevelRegion', value)"
        />
        <TextAtom
          v-for="(error, index) in (showErrors ? upperLevelErrors : [])"
          :key="`ll_err_${index}`"
          tag="p"
          :value="error"
          color="error-check"
        />
      </section>
    </div>
    <section v-else>
      <TextAtom
        tag="h2"
        :value="'inputs.detailed-address.subdivisions'"
      />
      <AutocompleteInput
        :items="subdivisionOptions"
        :select-value="subdivision"
        :loading="loadingSubdivisionOptions"
        return-object
        label-key="display"
        @searchInputUpdate="updateSubdivisionOptionsFromText"
        @setValue="updateSelectedSubdivision"
      />
      <TextAtom
        v-for="(error, index) in (showErrors ? countryErrors : [])"
        :key="`nat_err_${index}`"
        tag="p"
        :value="error"
        color="error-check"
      />
    </section>
    <!-- 3. Address line 1 -->
    <section>
      <TextAtom
        tag="h2"
        value="inputs.detailed-address.address-1"
      />
      <TextInput
        :value="addressLine1"
        @setValue="(value) => updateBoundValue('addressLine1', value)"
      />
      <TextAtom
        v-for="(error, index) in (showErrors ? addressLine1Errors : [])"
        :key="`id_err_${index}`"
        tag="p"
        :value="error"
        color="error-check"
      />
    </section>
    <!-- Address Line 2 & Postal Code -->
    <div
      class="flex"
      :class="{
        'flex-row': compact,
        'flex-col': !compact,
        'justify-space-between': compact,
      }"
    >
      <section>
        <TextAtom
          tag="h2"
          value="inputs.detailed-address.address-2"
        />
        <TextInput
          :value="addressLine2"
          @setValue="(value) => updateBoundValue('addressLine2', value)"
        />
      </section>
      <section>
        <TextAtom
          tag="h2"
          value="inputs.detailed-address.postal-code"
        />
        <TextInput
          :value="postalCode"
          @setValue="(value) => updateBoundValue('postalCode', value)"
        />
        <TextAtom
          v-for="(error, index) in (showErrors ? postalCodeError : [])"
          :key="`id_err_${index}`"
          tag="p"
          :value="error"
          color="error-check"
        />
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AutocompleteInput from '@/components/atoms/autocompletes/RegisterAutocomplete.vue';
import TextInput from '@/components/atoms/inputs/RegisterInput.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import services from '@/services';
import { parseName } from '@/utils/strings';
import { LABELS } from '@/constants/explorer/labels';

export default {
  name: 'DetailedAddressInputs',
  components: {
    TextAtom,
    AutocompleteInput,
    TextInput,
  },
  props: {
    // **  VALUES  **
    addressLine1: {
      type: String,
      default: null,
    },
    addressLine2: {
      type: String,
      default: null,
    },
    postalCode: {
      type: String,
      default: null,
    },
    lowerLevelRegion: {
      type: String,
      default: null,
    },
    upperLevelRegion: {
      type: String,
      default: null,
    },
    country: {
      type: Object,
      default: null,
    },
    // **  REQUIRED FIELDS FLAGS  **
    countryRequired: {
      type: Boolean,
      default: true,
    },
    upperLevelRequired: {
      type: Boolean,
      default: true,
    },
    lowerLevelRequired: {
      type: Boolean,
      default: true,
    },
    addressLine1Required: {
      type: Boolean,
      default: true,
    },
    postalCodeRequired: {
      type: Boolean,
      default: false,
    },
    // ** OTHER FLAGS **
    showErrors: {
      type: Boolean,
      default: false,
    },
    /** TODO: Implement this - geocode the address and return the coordinates
    geocode: {
      type: Boolean,
      default: false,
    },
     */
    // **  STYLES  **
    compact: {
      type: Boolean,
      default: false,
    },
    customSubdivisions: {
      type: Boolean,
      default: false,
    },
    // **  FORCED VALUES  **
    forceCountry: {
      type: Object,
      default: null,
    },
    // **  LABELS  **
    title: {
      type: String,
      default: 'inputs.detailed-address.title',
    },
  },
  data() {
    return {
      loadingSubdivisionOptions: false,
      subdivision: null,
      subdivisionOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      labelsByKey: 'explorer/labelsByKey',
    }),
    countries() {
      return this.labelsByKey(LABELS.NATIONALITIES);
    },
    parsedCountries() {
      return this.countries.map((country) => ({
        ...country,
        name: parseName(country.nationality_name),
      }));
    },
    // COMPUTED ERRORS
    countryErrors() {
      const errors = [];
      if (this.countryRequired && !this.country) {
        errors.push('field required');
      }
      return errors;
    },
    addressLine1Errors() {
      const errors = [];
      if (this.addressLine1Required && !this.addressLine1) {
        errors.push('field required');
      }
      return errors;
    },
    postalCodeError() {
      const errors = [];
      if (this.postalCodeRequired && !this.postalCode) {
        errors.push('field required');
      }
      return errors;
    },
    lowerLevelErrors() {
      const errors = [];
      if (this.lowerLevelRequired && !this.lowerLevelRegion) {
        errors.push('field required');
      }
      return errors;
    },
    upperLevelErrors() {
      const errors = [];
      if (this.upperLevelRequired && !this.upperLevelRegion) {
        errors.push('field required');
      }
      return errors;
    },
    errorsInFields() {
      return [
        ...this.countryErrors,
        ...this.addressLine1Errors,
        ...this.postalCodeError,
        ...this.lowerLevelErrors,
        ...this.upperLevelErrors,
      ].length > 0;
    },
  },
  watch: {
    errorsInFields: {
      handler(value) {
        this.updateBoundValue('hasErrors', value);
      },
      immediate: true,
    },
    lowerLevelRegion: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && !this.customSubdivisions) {
          this.updateSubdivisionOptionsFromText(newValue, true);
        }
      },
      immediate: true,
    },
  },
  async created() {
    if (this.forceCountry !== null) {
      const country = this.forceCountry;
      this.updateBoundValue('country', { ...country, name: parseName(country.nationality_name) });
    }
  },
  methods: {
    updateBoundValue(key, value) {
      this.$emit(`update:${key}`, value);
    },
    updateSelectedSubdivision({ city, upper_name: upperName } = {
      city: null,
      upper_name: null,
    }) {
      this.updateBoundValue('lowerLevelRegion', city);
      this.updateBoundValue('upperLevelRegion', upperName);
    },
    updateSubdivisionOptionsFromText(searchTerm, setInitial = false) {
      if (searchTerm) {
        this.loadingSubdivisionOptions = true;

        services.geoToolsService.getCommuneFromText({ searchTerm })
          .then(({ data: [subdivisionOptions] }) => {
            this.subdivisionOptions = subdivisionOptions;
            this.loadingSubdivisionOptions = false;
            if (setInitial && subdivisionOptions.length > 0) {
              const [firstMatch] = subdivisionOptions;
              this.subdivision = firstMatch;
              this.updateSelectedSubdivision(firstMatch);
            }
          })
          .catch((error) => {
            if (error.message !== 'canceled') {
              this.subdivisionOptions = [];
              this.loadingSubdivisionOptions = false;
            }
          });
      }
    },
  },
};
</script>
