<template>
  <div class="grouped-inputs">
    <TextAtom
      class="grouped-inputs__title"
      tag="h1"
      :value="title"
    />
    <section>
      <TextAtom
        tag="h2"
        :value="'inputs.communication-channels.email'"
      />
      <TextAtom
        v-if="emailSubtitle"
        tag="h4"
        :value="emailSubtitle"
      />
      <TextInput
        :value="email"
        @setValue="(value) => updateBoundValue('email', value)"
      />
      <TextAtom
        v-for="(error, index) in (showErrors ? emailErrors : [])"
        :key="`email_err_${index}`"
        tag="p"
        :value="error"
        color="error-check"
      />
    </section>
    <section v-if="!hidePhone">
      <TextAtom
        tag="h2"
        :value="'inputs.communication-channels.phone'"
      />
      <TextInput
        :value="phone"
        @setValue="(value) => updateBoundValue('phone', value)"
      />
      <TextAtom
        v-for="(error, index) in (showErrors ? phoneErrors : [])"
        :key="`phone_err_${index}`"
        tag="p"
        :value="error"
        color="error-check"
      />
    </section>
    <TextAtom
      v-for="(error, index) in (showErrors ? generalErrors : [])"
      :key="`gral_err_${index}`"
      tag="p"
      :value="error"
      color="error-check"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TextInput from '@/components/atoms/inputs/RegisterInput.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'CommunicationChannelsInput',
  components: {
    TextAtom,
    TextInput,
  },
  props: {
    email: {
      type: String,
      default: null,
    },
    phone: {
      type: String,
      default: null,
    },
    hidePhone: {
      type: Boolean,
      default: false,
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'inputs.communication-channels.title',
    },
    emailSubtitle: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({

    }),
    emailErrors() {
      const errors = [];
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (this.email && !emailRegex.test(this.email)) {
        errors.push('invalid format');
      }
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (this.hidePhone) {
        return errors;
      }
      const phoneRegex = /^\+?[0-9]{8,}$/;
      if (this.phone && !phoneRegex.test(this.phone)) {
        errors.push('invalid format');
      }
      return errors;
    },
    generalErrors() {
      const errors = [];
      if (this.emailErrors.length && this.phoneErrors.length) {
        errors.push('at least one method is required');
      }
      return errors;
    },
    errorsInFields() {
      return this.emailErrors.length > 0
        || this.phoneErrors.length > 0
        || this.generalErrors.length > 0;
    },
  },
  watch: {
    errorsInFields: {
      handler(value) {
        this.updateBoundValue('hasErrors', value);
      },
      immediate: true,
    },
  },
  methods: {
    updateBoundValue(key, value) {
      this.$emit(`update:${key}`, value);
    },
  },
};
</script>
