<template>
  <div
    class="flex flex-col justify-center gap-5"
    :style="{
      width: '90%',
      height: '100% !important',
      margin: '0 auto',
    }"
  >
    <ModalHeader
      :content-sections="['title', 'subtitle']"
      :title-text="'payments.customer-validation.title'"
      :subtitle-text="$t('payments.customer-validation.subtitle', { provider: currentProvider.name })"
      center-subtitle
    />
    <LoaderCircular
      v-if="initializingCustomer"
      :size="44"
      show
    />
    <CustomerFieldsValidation
      v-else-if="!successfulCheckout"
      v-model="customerInitializationResult.customer"
      :initial-values="customerInitializationResult.customer || {}"
      :required-fields="customerInitializationResult.required_fields || []"
      :optional-fields="customerInitializationResult.optional_fields || []"
      @validated="initializeCustomer"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LoaderCircular from '@/components/atoms/loaders/LoaderCircular.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import CustomerFieldsValidation from '@/components/organisms/payments/CustomerFieldsValidation.vue';

export default {
  name: 'CustomerInitialization',
  components: {
    ModalHeader,
    LoaderCircular,
    CustomerFieldsValidation,
  },
  computed: {
    ...mapGetters({
      currentProvider: 'payments/currentProvider',
      initializingCustomer: 'payments/initializingCustomer',
      customerInitializationResult: 'payments/customerInitializationResult',
      successfulCheckout: 'payments/successfulCheckout',
    }),
  },
  created() {
    // this.setBreadcrumbs({
    //   breadcrumbs: [
    //     {
    //       text: 'payments.breadcrumbs.root',
    //       route: this.$route.path,
    //     },
    //   ],
    // });
  },
  methods: {
    ...mapActions({
      setBreadcrumbs: 'utils/setBreadcrumbs',
      initializeCustomerInProvider: 'payments/initializeCustomer',
    }),
    initializeCustomer(validatedData) {
      this.initializeCustomerInProvider({
        updateBefore: true,
        updateData: validatedData,
      });
    },
  },
};
</script>
