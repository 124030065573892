<template>
  <button
    type="button"
    class="selection-button selection-button__multi-icon"
    :class="{ 'selection-button--selected': selected }"
    @click="$emit('clicked')"
  >
    <div v-if="iconsLeft.length > 0" id="left" class="selection-button__multi-icon__icons">
      <span
        v-for="(icon, index) in iconsLeft"
        :key="index"
      >
        <v-icon
          v-if="icon.category === 'mdi'"
          large
          :color="getComputedColorValue(selected ? '--secondary-light' : '--primary-dark')"
        >{{ icon.path }}</v-icon>
        <img
          v-else
          class="selection-button__multi-icon--icon"
          :src="require('@/assets/' + getAssetPath(icon))"
          :alt="icon.alt"
          :title="icon.title || icon.alt"
        />
      </span>
    </div>
    <span v-t="label" class="selection-button__multi-icon--text" />
    <div v-if="iconsRight.length > 0" id="right" class="selection-button__multi-icon__icons">
      <span
        v-for="(icon, index) in iconsRight"
        :key="index"
      >
        <v-icon v-if="icon.category === 'mdi'" large>{{ icon.path }}</v-icon>
        <img
          v-else
          class="selection-button__multi-icon--icon"
          :src="require('@/assets/' + getAssetPath(icon))"
          :alt="icon.alt"
          :title="icon.title || icon.alt"
        />
      </span>
    </div>
  </button>
</template>

<script>
import { getComputedColorValue } from '@/utils/colors';

export default {
  name: 'MultiIconSelectionButton',
  props: {
    label: {
      type: String,
      default: () => (''),
    },
    iconsLeft: {
      type: Array,
      default: () => ([]),
    },
    iconsRight: {
      type: Array,
      default: () => ([]),
    },
    selected: {
      type: Boolean,
      default: () => (false),
    },
  },
  methods: {
    getAssetPath(asset) {
      if (asset.category === 'icons') {
        return `icons/${asset.path}`;
      }
      if (asset.category === 'logos') {
        return `logos/${asset.path}`;
      }
      return asset.path;
    },
    getComputedColorValue,
  },
};
</script>
