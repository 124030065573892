<template>
  <div class="grouped-inputs">
    <TextAtom
      class="grouped-inputs__title"
      tag="h1"
      :value="title"
    />
    <div
      class="flex"
      :class="{
        'flex-row': compact,
        'flex-col': !compact,
        'justify-space-between': compact,
      }"
    >
      <section>
        <TextAtom
          tag="h2"
          :value="'inputs.national-identification.country' + (isResidency ? '-residency' : '')"
        />
        <AutocompleteInput
          :items="parsedCountries"
          :select-value="country"
          :readonly="!!forceCountry"
          return-object
          label-key="name"
          @setValue="(value) => updateBoundValue('country', value)"
        />
        <TextAtom
          v-for="(error, index) in (showErrors ? countryErrors : [])"
          :key="`nat_err_${index}`"
          tag="p"
          :value="error"
          color="error-check"
        />
      </section>
      <section>
        <TextAtom
          tag="h2"
          value="inputs.national-identification.identification-type"
        />
        <AutocompleteInput
          :items="nationalIdentificationTypes"
          :value="identificationType"
          :disabled-value="!country"
          return-object
          @input="(value) => updateBoundValue('identificationType', value)"
        />
        <TextAtom
          v-for="(error, index) in (showErrors ? identificationTypeErrors : [])"
          :key="`idt_err_${index}`"
          tag="p"
          :value="error"
          color="error-check"
        />
      </section>
    </div>
    <section>
      <TextAtom
        tag="h2"
        value="inputs.national-identification.identification"
      />
      <TextInput
        :value="identification"
        :disabled="!(country & identificationType)"
        @setValue="(value) => updateBoundValue('identification', value)"
      />
      <TextAtom
        v-for="(error, index) in (showErrors ? identificationErrors : [])"
        :key="`id_err_${index}`"
        tag="p"
        :value="error"
        color="error-check"
      />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AutocompleteInput from '@/components/atoms/autocompletes/RegisterAutocomplete.vue';
import TextInput from '@/components/atoms/inputs/RegisterInput.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import { parseName } from '@/utils/strings';
import { LABELS } from '@/constants/explorer/labels';

export default {
  name: 'NationalIdentificationSelection',
  components: {
    TextAtom,
    AutocompleteInput,
    TextInput,
  },
  props: {
    country: {
      type: Object,
      default: null,
    },
    identificationType: {
      type: Object,
      default: null,
    },
    identification: {
      type: String,
      default: null,
    },
    forceCountry: {
      type: Object,
      default: null,
    },
    countryRequired: {
      type: Boolean,
      default: false,
    },
    identificationTypeRequired: {
      type: Boolean,
      default: false,
    },
    identificationRequired: {
      type: Boolean,
      default: false,
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'inputs.national-identification.title',
    },
    overrideDocumentTypes: {
      type: [Object, Array],
      default: null,
    },
    isResidency: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      documentTypes: 'options/documentTypes',
      labelsByKey: 'explorer/labelsByKey',
    }),
    countries() {
      return this.labelsByKey(LABELS.NATIONALITIES);
    },
    parsedCountries() {
      return this.countries.map((country) => ({
        ...country,
        name: parseName(country.nationality_name),
      }));
    },
    nationalIdentificationTypes() {
      if (!this.country) {
        return [];
      }
      if (this.overrideDocumentTypes) {
        if (Array.isArray(this.overrideDocumentTypes)) {
          return this.overrideDocumentTypes;
        }
        const documentTypes = this.overrideDocumentTypes[this.country.nationality_name]
                              || this.overrideDocumentTypes[this.country.id];
        if (documentTypes) return documentTypes;
      }
      return this.documentTypes[this.country.id].map((documentType) => ({
        ...documentType,
        name: parseName(documentType.identification_type_name),
      }));
    },
    countryErrors() {
      const errors = [];
      if (this.countryRequired && !this.country) {
        errors.push('field required');
      }
      return errors;
    },
    identificationTypeErrors() {
      const errors = [];
      if (this.identificationTypeRequired && !this.identificationType) {
        errors.push('field required');
      }
      return errors;
    },
    identificationErrors() {
      const errors = [];
      const onlyAlphaNumeric = /^[a-z0-9]+$/i;
      if (this.identificationRequired && !this.identification) {
        errors.push('field required');
      }
      if (this.identification && !onlyAlphaNumeric.test(this.identification)) {
        errors.push('only alphanumeric characters');
      }
      if (this.identificationType?.validation) {
        const regex = new RegExp(this.identificationType.validation);
        if (!regex.test(this.identification)) {
          errors.push('invalid format');
        }
      }
      return errors;
    },
    errorsInFields() {
      return this.countryErrors.length > 0
        || this.identificationTypeErrors.length > 0
        || this.identificationErrors.length > 0;
    },
  },
  watch: {
    errorsInFields: {
      handler(value) {
        this.updateBoundValue('hasErrors', value);
      },
      immediate: true,
    },
  },
  async created() {
    if (this.forceCountry !== null) {
      const country = this.forceCountry;
      this.updateBoundValue('country', { ...country, name: parseName(country.nationality_name) });
    }
  },
  methods: {
    updateBoundValue(key, value) {
      this.$emit(`update:${key}`, value);
    },
  },
};
</script>
