<template>
  <div
    class="payments"
    :class="{ payments__mobile: mobile }"
  >
    <PaymentsHeader @backStep="confirmExit" />

    <div class="payments__content">
      <SVGIcon
        v-if="!mobile"
        icon="back-arrow.svg"
        size="22"
        class="payments__content__back-arrow"
        style="position: absolute; color: red; cursor: pointer;"
        @click="confirmExit"
      />
      <!-- Payment Flow -->
      <SpinningLoader v-if="loading && !currentPayment" active />
      <PaymentCheckout v-else-if="currentPayment && !successfulCheckout" />
      <PaymentIntent v-else-if="successfulCheckout" />
    </div>
  </div>
</template>

<script>

/**
 * Desired Flow:
 *  - Initialize payment from clicking on a button of pending actions
 *  - Select payment method (METHOD, not PROVIDER)
 *  - Select payment provider that supports the selected method
 *  - Initialize payment with the selected provider
 */

import { mapActions, mapGetters } from 'vuex';
import SVGIcon from '@/components/atoms/icons/SvgIcon.vue';
import SpinningLoader from '@/components/organisms/general/SpinningLoader.vue';
import PaymentCheckout from '@/components/organisms/payments/PaymentCheckout.vue';
import PaymentIntent from '@/components/organisms/payments/PaymentIntent.vue';
import PaymentsHeader from '@/components/organisms/payments/PaymentsHeader.vue';
import { LABELS } from '@/constants/explorer/labels';

export default {
  name: 'TetherPayments',
  components: {
    PaymentsHeader,
    PaymentCheckout,
    PaymentIntent,
    SpinningLoader,
    SVGIcon,
  },
  computed: {
    ...mapGetters({
      currentPayment: 'payments/currentPayment',
      currentProvider: 'payments/currentProvider',
      loading: 'payments/loading',
      paymentsRedirectRoute: 'payments/redirectRoute',
      successfulCheckout: 'payments/successfulCheckout',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    currentPayment: {
      handler: 'checkValidPayment',
      immediate: true,
    },
    loading: {
      handler: 'checkValidPayment',
      immediate: true,
    },
  },
  mounted() {
    this.checkLabels({ keysLabels: [LABELS.SPECIALTIES] });
    this.setBreadcrumbs({
      breadcrumbs: [
        {
          text: 'payments.breadcrumbs.root',
          route: this.$route.path,
        },
      ],
    });
  },
  methods: {
    ...mapActions({
      setBreadcrumbs: 'utils/setBreadcrumbs',
      activateModal: 'utils/activateModal',
      resetPaymentStore: 'payments/resetStore',
      setLoading: 'payments/setLoading',
      checkLabels: 'explorer/checkLabels',
    }),
    cancelFlow() {
      this.setLoading({ loading: true });
      this.$router.push(this.paymentsRedirectRoute.path);
      this.resetPaymentStore();
      this.setLoading({ loading: false });
    },
    checkValidPayment() {
      if (!this.loading && !this.currentPayment) {
        this.cancelFlow();
      }
    },
    confirmExit() {
      const callbacks = {
        onConfirm: () => {
          this.cancelFlow();
        },
      };
      this.activateModal({
        name: 'PaymentStatus',
        data: {
          status: 'exit',
        },
        callbacks,
      });
    },
  },
};
</script>
