var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    width: _vm.narrow ? '40%' : '100%',
    margin: '0 auto',
  })},[_c('MobileFormContent',{attrs:{"content-sections":[
      'smallTitle',
      'subtitle',
    ],"small-title-text":'payments.methods.title',"subtitle-text":_vm.$t('payments.methods.subtitle', { payment_name: _vm.payment.name })}}),_vm._l((_vm.availableMethods),function(method){return _c('MultiIconSelectionButton',{key:method.original.code_name,attrs:{"label":method.label,"icons-left":method.iconsLeft,"icons-right":method.iconsRight,"selected":_vm.currentMethod?.code_name === method.original.code_name},on:{"clicked":function($event){return _vm.selectPaymentMethod(method.original)}}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }