<template>
  <div>
    <div
      class="flex flex-col justify-center gap-5"
    >
      <div class="flex justify-center flex-1">
        <MethodSelection v-if="!currentMethod" :narrow="!mobile" />
        <ProviderSelection v-if="currentMethod" :narrow="!mobile" />
      </div>
      <CustomerInitialization
        v-if="providerNeedsCustomer"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MethodSelection from '@/components/organisms/payments/MethodSelection.vue';
import ProviderSelection from '@/components/organisms/payments/ProviderSelection.vue';
import CustomerInitialization from '@/components/organisms/payments/CustomerInitialization.vue';

export default {
  name: 'PaymentCheckout',
  components: {
    MethodSelection,
    ProviderSelection,
    CustomerInitialization,
  },
  data: () => ({

  }),
  computed: {
    ...mapGetters({
      currentMethod: 'payments/currentMethod',
      currentProvider: 'payments/currentProvider',
      successfulCheckout: 'payments/successfulCheckout',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    providerNeedsCustomer() {
      return this.currentProvider && !this.successfulCheckout;
    },
  },
  created() {

  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>

</style>
