<template>
  <div
    :style="{
      width: narrow ? '40%' : '100%',
      margin: '0 auto',
    }"
  >
    <MobileFormContent
      :content-sections="[
        'smallTitle',
        'subtitle',
      ]"
      :small-title-text="'payments.methods.title'"
      :subtitle-text="$t('payments.methods.subtitle', { payment_name: payment.name })"
    />

    <MultiIconSelectionButton
      v-for="method in availableMethods"
      :key="method.original.code_name"
      :label="method.label"
      :icons-left="method.iconsLeft"
      :icons-right="method.iconsRight"
      :selected="currentMethod?.code_name === method.original.code_name"
      @clicked="selectPaymentMethod(method.original)"
    />
  </div>
</template>

<script>

import MultiIconSelectionButton from '@/components/atoms/buttons/MultiIconButton.vue';
import MobileFormContent from '@/components/molecules/modals/Content.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PaymentMethodSelection',
  components: {
    MobileFormContent,
    MultiIconSelectionButton,
  },
  props: {
    narrow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      methods: 'payments/methods',
      payment: 'payments/currentPayment',
      currentMethod: 'payments/currentMethod',
    }),
    availableMethods() {
      return this.methods.map((method) => ({
        label: this.$t(`payments.methods.${method.code_name}`),
        iconsLeft: [
          {
            category: 'mdi',
            path: 'mdi-bank-transfer',
            label: 'Bank transfer icon',
          },
        ],
        iconsRight: method.providers.map((provider) => ({
          path: `providers/payments/${provider.provider_code}.svg`,
          label: `${provider.name} logo`,
          category: 'logos',
        })),
        original: method,
      }));
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    this.setBreadcrumbs({
      breadcrumbs: [
        {
          text: 'payments.breadcrumbs.root',
          route: this.$router.resolve({ name: 'MyPayments' }).href,
        },
        {
          text: 'payments.breadcrumbs.method-selection',
          route: this.$route.fullPath,
        },
      ],
    });
  },
  methods: {
    ...mapActions({
      setCurrentMethod: 'payments/setCurrentMethod',
      setBreadcrumbs: 'utils/setBreadcrumbs',
    }),
    selectPaymentMethod(method) {
      if (method.code_name === this.currentMethod?.code_name) {
        this.setCurrentMethod({ method: null });
        return;
      }
      this.setCurrentMethod({ method });
    },
  },
};
</script>
