<template>
  <div>
    <div
      class="flex gap-5 justify-center"
      :class="{
        'flex-col': mobile,
        'flex-row': !mobile,
      }"
    >
      <div
        :style="{
          width: mobile ? '100%' : '45%',
        }"
      >
        <CommunicationChannels
          :email.sync="contactData.email"
          :email-subtitle="currentProvider.provider_code ? 'payments.customer-validation.fields.email' : null"
          :phone.sync="contactData.phone"
          :show-errors="showErrors"
          :has-errors.sync="hasErrors.communicationChannels"
          hide-phone
        />
        <PersonalIdentification
          :first-name.sync="personalIdentificationData.firstName"
          :first-lastname.sync="personalIdentificationData.lastName"
          :second-name.sync="personalIdentificationData.secondName"
          :second-lastname.sync="personalIdentificationData.secondLastname"
          :show-errors="showErrors"
          :has-errors.sync="hasErrors.personalIdentification"
          simple
          compact
        />
        <NationalIdentification
          :country.sync="nationalIdentificationData.country"
          :identification-type.sync="nationalIdentificationData.identificationType"
          :identification.sync="nationalIdentificationData.identification"

          :country-required="requiredFields.includes('country')"
          :identification-type-required="requiredFields.includes('identification_type')"
          :identification-required="requiredFields.includes('identification')"

          :show-errors="showErrors"
          :has-errors.sync="hasErrors.nationalIdentification"

          :force-country="forcedCountry"
          :override-document-types="overrideDocumentTypes"
          is-residency
          compact
        />
      </div>

      <div
        v-if="requiredFields.includes('address')"
        :style="{
          width: mobile ? '100%' : '45%',
        }"
      >
        <DetailedAddress
          :address-line1.sync="addressData.addressLine1"
          :address-line2.sync="addressData.addressLine2"
          :postal-code.sync="addressData.postalCode"
          :lower-level-region.sync="addressData.lowerLevelRegion"
          :upper-level-region.sync="addressData.upperLevelRegion"
          :country.sync="addressData.country"
          :show-errors="showErrors"
          :has-errors.sync="hasErrors.address"
          title="payments.customer-validation.fields.address"
          compact
        />
      </div>
    </div>
    <CallToActions
      :active-value="!hasErrorsInAnyField"
      :main-button-text="'Confirmar'"
      :types="['main']"
      :spaced="false"
      @continue="validateCustomerData"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CommunicationChannels from '@/components/molecules/grouped-inputs/CommunicationChannels.vue';
import NationalIdentification from '@/components/molecules/grouped-inputs/NationalIdentification.vue';
import PersonalIdentification from '@/components/molecules/grouped-inputs/PersonalIdentification.vue';
import DetailedAddress from '@/components/molecules/grouped-inputs/DetailedAddress.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import { DOCUMENT_TYPES as PAYMENT_DOCUMENT_TYPES } from '@/utils/payments';
import { LABELS } from '@/constants/explorer/labels';

export default {
  name: 'CustomerFieldsValidation',
  components: {
    NationalIdentification,
    PersonalIdentification,
    CallToActions,
    CommunicationChannels,
    DetailedAddress,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    requiredFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showErrors: false,
      personalIdentificationData: {
        firstName: null,
        lastName: null,
      },
      nationalIdentificationData: {
        country: null,
        identificationType: null,
        identification: null,
      },
      contactData: {
        email: null,
        phone: null,
      },
      addressData: {
        addressLine1: null,
        addressLine2: null,
        postalCode: null,
        lowerLevelRegion: null,
        upperLevelRegion: null,
        country: null,
      },
      hasErrors: {
        personalIdentification: false,
        nationalIdentification: false,
        contact: false,
        location: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentProvider: 'payments/currentProvider',
      legalGuardian: 'authentication/legalGuardian',
      userEmail: 'authentication/userEmail',
      userPhone: 'authentication/userPhone',
      labelsByKey: 'explorer/labelsByKey',
    }),
    nationalities() {
      return this.labelsByKey(LABELS.NATIONALITIES);
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    forcedCountry() {
      if (this.currentProvider?.provider_code === 'co_belvo') {
        // FIXME: Figure out how to do this properly
        return this.nationalities.find(({ nationality_name: name }) => name.toLowerCase() === 'colombia');
      }
      return null;
    },
    stagedCustomerData() {
      return {
        ...this.personalIdentificationData,
        ...this.parseNationalIdentificationData(this.nationalIdentificationData),
        ...this.contactData,
        address: this.parseAddressData(this.addressData),
      };
    },
    hasErrorsInAnyField() {
      return Object.values(this.hasErrors).some((hasError) => hasError);
    },
    overrideDocumentTypes() {
      if (['co_belvo', 'co_fintoc'].includes(this.currentProvider?.provider_code)) {
        return PAYMENT_DOCUMENT_TYPES.COLOMBIA;
      }
      // FIXME: See if we're going to use this for chile
      if (this.currentProvider?.provider_code === 'cl_kushki') {
        return PAYMENT_DOCUMENT_TYPES.CHILE;
      }
      return null;
    },
  },
  created() {
    this.loadDefaultData();
  },
  methods: {
    parseNationalIdentificationData({ country, identificationType, identification }) {
      return {
        country: country?.nationality_name,
        identificationType: identificationType?.code,
        identification,
      };
    },
    parseAddressData(addressData) {
      const {
        addressLine1,
        addressLine2,
        postalCode,
        lowerLevelRegion,
        upperLevelRegion,
        country,
      } = addressData;
      return {
        addressLine1,
        addressLine2,
        postalCode,
        lowerRegion: lowerLevelRegion,
        upperRegion: upperLevelRegion,
        country: country?.country || country?.name,
      };
    },
    validateCustomerData() {
      if (this.hasErrorsInAnyField) {
        this.showErrors = true;
      } else {
        this.$emit('validated', { ...this.stagedCustomerData });
      }
    },
    loadDefaultData() {
      const {
        address: legalGuardianAddress,
        firstName: legalGuardianFirstName,
        firstLastname: legalGuardianLastName,
        identificationType: legalGuardianIdentificationType,
        identificationNumber: legalGuardianIdentification,
        nationality: legalGuardianNationality,
      } = this.legalGuardian;

      const {
        identificationType,
        identification,
        country,
        firstName,
        lastName,
        address,
        phone,
        email,
      } = this.value;

      const countryObject = country
        ? this.nationalities.find(({ country: countryCode }) => country === countryCode)
        : null;

      const identificationTypeObject = PAYMENT_DOCUMENT_TYPES.COLOMBIA.find(({ code }) => code === identificationType);

      // Data is loaded from the legal guardian if it's not provided from the payments service
      this.nationalIdentificationData = {
        country: countryObject ?? legalGuardianNationality,
        identificationType: identificationTypeObject ?? legalGuardianIdentificationType,
        identification: identification ?? legalGuardianIdentification,
      };
      this.personalIdentificationData = {
        firstName: firstName ?? legalGuardianFirstName,
        lastName: lastName ?? legalGuardianLastName,
      };
      this.contactData = {
        phone: phone ?? this.userPhone,
        email: email ?? this.userEmail,
      };

      const handleCustomerAddress = (customerAddress) => {
        if (!customerAddress) {
          return null;
        }
        const {
          upperRegion: upperLevelRegion,
          lowerRegion: lowerLevelRegion,
          country: countryCode,
          addressLine1,
          addressLine2,
          postalCode,
        } = customerAddress;
        const addressCountryObject = this.nationalities.find(({ country: code }) => code === countryCode);

        return {
          addressLine1,
          addressLine2,
          postalCode,
          lowerLevelRegion,
          upperLevelRegion,
          country: addressCountryObject ?? countryObject ?? legalGuardianNationality,
        };
      };

      const handleLegalGuardianAddress = (addressArray) => {
        if (addressArray === null) {
          return null;
        }
        const [{ address_details: addressDetails = null, city } = {}] = addressArray;

        // Details comes in the format 'street, number, city', we just want the street and number
        const [street, number] = addressDetails?.split(',') ?? [];

        if (!street || !number) {
          return {};
        }
        return {
          addressLine1: `${street.trim()} ${number.trim()}`,
          addressLine2: null,
          postalCode: null,
          lowerLevelRegion: city,
          upperLevelRegion: null,
          country: countryObject ?? legalGuardianNationality,
        };
      };

      this.addressData = {
        ...this.addressData,
        ...(handleCustomerAddress(address) || handleLegalGuardianAddress(legalGuardianAddress)),
      };
    },
  },
};
</script>
