var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('TextAtom',{attrs:{"tag":"h1","value":_vm.title}}),_c('div',{staticClass:"flex flex-wrap",class:{ 'gap-4': _vm.compact }},[_c('section',{style:({
        flex: _vm.compact ? '45%' : 'unset',
      })},[_c('TextAtom',{attrs:{"tag":"h2","value":"inputs.personal-identification.first-name"}}),_c('TextInput',{attrs:{"value":_vm.firstName},on:{"setValue":(value) => _vm.updateBoundValue('firstName', value)}}),_vm._l(((_vm.showErrors ? _vm.firstNameErrors : [])),function(error,index){return _c('TextAtom',{key:`fn_err_${index}`,attrs:{"tag":"p","value":error,"color":"error-check"}})})],2),(!_vm.simple && !_vm.hideSecondName)?_c('section',{style:({
        flex: _vm.compact ? '45%' : 'unset',
      })},[_c('TextAtom',{attrs:{"tag":"h2","value":"inputs.personal-identification.second-name"}}),_c('TextInput',{attrs:{"value":_vm.secondName},on:{"setValue":(value) => _vm.updateBoundValue('secondName', value)}}),_vm._l(((_vm.showErrors ? _vm.secondNameErrors : [])),function(error,index){return _c('TextAtom',{key:`sn_err_${index}`,attrs:{"tag":"p","value":error,"color":"error-check"}})})],2):_vm._e(),_c('section',{style:({
        flex: _vm.compact ? '45%' : 'unset',
      })},[_c('TextAtom',{attrs:{"tag":"h2","value":"inputs.personal-identification.first-lastname"}}),_c('TextInput',{attrs:{"value":_vm.firstLastname},on:{"setValue":(value) => _vm.updateBoundValue('firstLastname', value)}}),_vm._l(((_vm.showErrors ? _vm.firstLastnameErrors : [])),function(error,index){return _c('TextAtom',{key:`fl_err_${index}`,attrs:{"tag":"p","value":error,"color":"error-check"}})})],2),(!_vm.simple && !_vm.hideSecondLastname)?_c('section',{style:({
        flex: _vm.compact ? '45%' : 'unset',
      })},[_c('TextAtom',{attrs:{"tag":"h2","value":"inputs.personal-identification.second-lastname"}}),_c('TextInput',{attrs:{"value":_vm.secondLastname},on:{"setValue":(value) => _vm.updateBoundValue('secondLastname', value)}}),_vm._l(((_vm.showErrors ? _vm.secondLastnameErrors : [])),function(error,index){return _c('TextAtom',{key:`sl_err_${index}`,attrs:{"tag":"p","value":error,"color":"error-check"}})})],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }