<template>
  <section>
    <MobileHeader
      v-if="mobile"
      :content-sections="['actions', 'logo']"
      inline-logo
      small-logo
      @back="$emit('backStep')"
    />
    <!--
      TODO: Merge Styles:
      The new style should be backwards-compatible with the Mobile Header (mobile has same behavior)
      update: see how this speaks to the rest
      -->
    <div v-else class="payments__navbar">
      <div class="payments__navbar__logo">
        <!-- Tether Logo (squared - full height) -->
        <TetherLogo
          alignment="isotype"
          width="60"
        />
      </div>
      <div v-if="!mobile" class="payments__navbar__navigation">
        <!-- Breadcrumbs -->
        <Breadcrumbs
          style="margin-left: 20px;"
          :breadcrumb-array="breadcrumbs"
        />
      </div>
    </div>
  </section>
</template>

<script>

import TetherLogo from '@/components/molecules/logos/TetherLogo.vue';
import MobileHeader from '@/components/molecules/modals/Header.vue';
import Breadcrumbs from '@/components/molecules/sections/Breadcrumbs.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PaymentsHeader',
  components: {
    Breadcrumbs,
    MobileHeader,
    TetherLogo,
  },
  computed: {
    ...mapGetters({
      breadcrumbs: 'utils/breadcrumbs',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  created() {
    this.setBreadcrumbs({
      breadcrumbs: [
        {
          text: 'payments.breadcrumbs.root',
          route: this.$route.path,
        },
      ],
    });
  },
  methods: {
    ...mapActions({
      setBreadcrumbs: 'utils/setBreadcrumbs',
    }),
  },
};
</script>

<style>

</style>
