<template>
  <div>
    <TextAtom
      tag="h1"
      :value="title"
    />
    <div class="flex flex-wrap" :class="{ 'gap-4': compact }">
      <!-- First Name -->
      <section
        :style="{
          flex: compact ? '45%' : 'unset',
        }"
      >
        <TextAtom
          tag="h2"
          value="inputs.personal-identification.first-name"
        />
        <TextInput
          :value="firstName"
          @setValue="(value) => updateBoundValue('firstName', value)"
        />
        <TextAtom
          v-for="(error, index) in (showErrors ? firstNameErrors : [])"
          :key="`fn_err_${index}`"
          tag="p"
          :value="error"
          color="error-check"
        />
      </section>

      <!-- Second Name -->
      <section
        v-if="!simple && !hideSecondName"
        :style="{
          flex: compact ? '45%' : 'unset',
        }"
      >
        <TextAtom
          tag="h2"
          value="inputs.personal-identification.second-name"
        />
        <TextInput
          :value="secondName"
          @setValue="(value) => updateBoundValue('secondName', value)"
        />
        <TextAtom
          v-for="(error, index) in (showErrors ? secondNameErrors : [])"
          :key="`sn_err_${index}`"
          tag="p"
          :value="error"
          color="error-check"
        />
      </section>
      <!-- First last name -->
      <section
        :style="{
          flex: compact ? '45%' : 'unset',
        }"
      >
        <TextAtom
          tag="h2"
          value="inputs.personal-identification.first-lastname"
        />
        <TextInput
          :value="firstLastname"
          @setValue="(value) => updateBoundValue('firstLastname', value)"
        />
        <TextAtom
          v-for="(error, index) in (showErrors ? firstLastnameErrors : [])"
          :key="`fl_err_${index}`"
          tag="p"
          :value="error"
          color="error-check"
        />
      </section>
      <!-- Second last name -->
      <section
        v-if="!simple && !hideSecondLastname"
        :style="{
          flex: compact ? '45%' : 'unset',
        }"
      >
        <TextAtom
          tag="h2"
          value="inputs.personal-identification.second-lastname"
        />
        <TextInput
          :value="secondLastname"
          @setValue="(value) => updateBoundValue('secondLastname', value)"
        />
        <TextAtom
          v-for="(error, index) in (showErrors ? secondLastnameErrors : [])"
          :key="`sl_err_${index}`"
          tag="p"
          :value="error"
          color="error-check"
        />
      </section>
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/atoms/inputs/RegisterInput.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'PersonalIdentificationInputs',
  components: {
    TextAtom,
    TextInput,
  },
  props: {
    firstName: {
      type: String,
      default: null,
    },
    firstLastname: {
      type: String,
      default: null,
    },
    secondName: {
      type: String,
      default: null,
    },
    secondLastname: {
      type: Object,
      default: null,
    },
    simple: {
      // Whether to display only the first name and last name fields
      type: Boolean,
      default: false,
    },
    hideSecondName: {
      // Whether to hide the second name field
      type: Boolean,
      default: false,
    },
    hideSecondLastname: {
      // Whether to hide the second last name field
      type: Boolean,
      default: false,
    },
    showErrors: {
      // Whether to show errors in the fields
      type: Boolean,
      default: false,
    },
    title: {
      // Title to display
      type: String,
      default: 'inputs.personal-identification.title',
    },
    compact: {
      // Whether to display the fields in a compact way
      type: Boolean,
      default: false,
    },
  },
  computed: {
    firstNameErrors() {
      const errors = [];
      if (!this.firstName) {
        errors.push('field required');
      }
      if (!this.testName(this.firstName)) {
        errors.push('invalid value');
      }
      return errors;
    },
    secondNameErrors() {
      // No errors for second name
      const errors = [];
      if (this.secondName && !this.testName(this.secondName)) {
        errors.push('invalid value');
      }
      return errors;
    },
    firstLastnameErrors() {
      const errors = [];
      if (!this.firstName) {
        errors.push('field required');
      }
      if (!this.testName(this.firstLastname)) {
        errors.push('invalid value');
      }
      return errors;
    },
    secondLastnameErrors() {
      // No errors for second last name
      const errors = [];
      if (this.secondLastname && !this.testName(this.secondLastname)) {
        errors.push('invalid value');
      }
      return errors;
    },
    errorsInFields() {
      return this.firstNameErrors.length > 0
        || this.firstLastnameErrors.length > 0
        || this.secondNameErrors.length > 0
        || this.secondLastnameErrors.length > 0;
    },
  },
  watch: {
    errorsInFields: {
      handler(value) {
        this.updateBoundValue('hasErrors', value);
      },
      immediate: true,
    },
  },
  methods: {
    updateBoundValue(key, value) {
      this.$emit(`update:${key}`, value);
    },
    testName(value) {
      const regex = /^[A-Za-z][A-Za-z _]*$/;
      return regex.test(value);
    },
  },
};
</script>
