<template>
  <SpinningLoader v-if="submitting || !widgetInterface" active />
  <PaymentEmbeddedWidget
    v-else-if="widgetInterface?.method === INTEGRATION_METHODS.WIDGET"
    :interface="widgetInterface"
    @success="(data) => submitFlowResult({ flowSubmissionData: data })"
    @exit="exit"
    @retry="retry"
  />
  <PaymentRedirectedWidget
    v-else-if="widgetInterface?.method === INTEGRATION_METHODS.REDIRECTION"
    :interface="widgetInterface"
    @success="(data) => submitFlowResult({ flowSubmissionData: data, status: 'redirected' })"
  />
</template>

<script>

import SpinningLoader from '@/components/organisms/general/SpinningLoader.vue';
import PaymentEmbeddedWidget from '@/components/organisms/widget/PaymentEmbeddedWidget.vue';
import PaymentRedirectedWidget from '@/components/organisms/widget/PaymentRedirectedWidget.vue';
import { INTEGRATION_METHODS } from '@/constants/payments';
import { getProviderInterface } from '@/utils/payments';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PaymentIntent',
  components: {
    PaymentEmbeddedWidget,
    PaymentRedirectedWidget,
    SpinningLoader,
  },
  data: () => ({
    submitting: false,
    widgetInterface: null,
    INTEGRATION_METHODS,
  }),
  computed: {
    ...mapGetters({
      currentProvider: 'payments/currentProvider',
      currentMethod: 'payments/currentMethod',
      breadcrumbs: 'utils/breadcrumbs',
      paymentsRedirectRoute: 'payments/redirectRoute',
    }),
  },
  watch: {
    currentProvider() {
      this.initializeAttempt();
    },
  },
  created() {
    if (this.breadcrumbs.length < 3) {
      this.addBreadcrumb({
        breadcrumb: {
          text: `payments.breadcrumbs.${this.currentMethod.code_name}`,
          route: this.$route.path,
        },
      });
    }
    this.initializeAttempt();
  },
  methods: {
    ...mapActions({
      addBreadcrumb: 'utils/addBreadcrumb',
      initializePaymentFlow: 'payments/initializePaymentFlow',
      snackbarError: 'utils/error',
      resetPaymentFlow: 'payments/resetPaymentFlow',
      setCurrentPayment: 'payments/setCurrentPayment',
      activateModal: 'utils/activateModal',
      logSuccessfulPaymentTime: 'digitalEnrollment/logSuccessfulPaymentTime',
    }),
    async redirect() {
      if (this.paymentsRedirectRoute) {
        this.$router.push(this.paymentsRedirectRoute.path);
      } else {
        // Fallback to payments page if no redirect route is set
        this.$router.push({ name: 'MyPayments' });
      }
    },
    initializeOutcomeModal({ status, callbacks }) {
      // status can be: 'success', 'error', 'retry'
      this.activateModal({
        name: 'PaymentStatus',
        data: {
          status,
          textArgs: {
            provider: this.currentProvider.name,
          },
        },
        callbacks,
      });
    },
    submitFlowResult({ flowSubmissionData, status = 'success' }) {
      this.submitting = true;
      this.widgetInterface.submitFlow({ flowSubmissionData }).then(() => {
        // BEGIN TEMP HACK FOR DGE
        this.logSuccessfulPaymentTime();
        // END TEMP HACK FOR DGE
        this.redirect().then(() => {
          this.initializeOutcomeModal({
            status,
          });
          this.resetPaymentFlow();
        });
      }).catch(() => {
        this.initializeOutcomeModal({
          status: 'error',
        });
        this.resetPaymentFlow();
      }).finally(() => {
        this.submitting = false;
      });
    },
    exit() {
      this.redirect().then(() => {
        this.initializeOutcomeModal({
          status: 'error',
        });
        this.resetPaymentFlow();
      });
    },
    retry() {
      this.resetPaymentFlow({ keepPayment: true });
      const callbacks = {
        onCancel: () => {
          this.redirect().then(() => this.setCurrentPayment({ payment: null }));
        },
      };
      this.initializeOutcomeModal({
        status: 'retry',
        callbacks,
      });
    },
    async loadInterface({ flowInitializationData }) {
      this.widgetInterface = getProviderInterface({ provider: this.currentProvider, flowInitializationData });
    },
    async initializeAttempt() {
      if (this.currentMethod && this.currentProvider) {
        this.initializePaymentFlow()
          .then((flowInitializationData) => {
            this.loadInterface({ flowInitializationData });
          }).catch(() => {
            this.snackbarError({
              message: 'payments.errors.flow_initialization',
            });
          });
      }
    },
  },
};
</script>
