<template>
  <SpinningLoader v-if="loading" :active="loading" />
</template>

<script>
import SpinningLoader from '@/components/organisms/general/SpinningLoader.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PaymentExternalWidget',
  components: {
    SpinningLoader,
  },
  props: {
    interface: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      loading: 'payments/loading',
      currentProvider: 'payments/currentProvider',
    }),
  },
  mounted() {
    // Upon creation of the component, redirect to the external payment page
    const successfulRedirect = () => {
      this.deactivateModal();
      this.$emit('success');
    };

    const callbacks = {
      onRedirect: successfulRedirect,
    };
    this.activateModal({
      name: 'PaymentRedirection',
      data: {
        toLink: this.interface.toLink,
        providerName: this.currentProvider.name,
      },
      callbacks,
    });
    const openedWindow = this.interface.invoke();
    if (openedWindow) {
      successfulRedirect();
    }
  },
  methods: {
    ...mapActions({
      activateModal: 'utils/activateModal',
      deactivateModal: 'utils/deactivateModal',
    }),
  },
};
</script>
